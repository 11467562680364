import { RadioGroup } from "@/components/ui/radio-group";
import { TableBody, TableCell, TableRow } from "@/components/ui/table";
import { DASS_21 } from "@/data/dass-21";
import { Dass21TableCellOption } from "./dass-21-table-cell-option";
import {
  FormControl,
  FormField,
  // FormItem,
  FormItemWithoutDiv,
  // FormMessage,
} from "@/components/ui/form";
import { useFormContext } from "react-hook-form";
import { cn } from "@/lib/utils";

export function Dass21TableBody() {
  const form = useFormContext();

  return (
    <TableBody>
      {DASS_21.items.map((dass21Item) => (
        <FormField
          key={dass21Item.id}
          control={form.control}
          name={dass21Item.id}
          render={({ field, fieldState }) => (
            <FormItemWithoutDiv>
              <FormControl>
                <RadioGroup
                  asChild
                  name={field.name}
                  defaultValue=""
                  onValueChange={field.onChange}
                >
                  <TableRow
                    className={cn("odd:bg-gray-100", {
                      "text-red-600": fieldState.invalid,
                      "outline-dashed outline-2 outline-offset-8 outline-current ":
                        fieldState.invalid,
                    })}
                    style={
                      fieldState.invalid
                        ? {
                            outlineColor: "rgb(220 38 38)",
                            outlineOffset: "-8px",
                            outlineStyle: "dashed",
                            outlineWidth: "2px",
                          }
                        : {
                            outlineColor: "rgb(0 0 0)",
                            outlineOffset: "0px",
                            outlineStyle: "none",
                            outlineWidth: "0px",
                          }
                    }
                  >
                    <TableCell>{dass21Item.number}.</TableCell>
                    <TableCell>{dass21Item.statement}</TableCell>
                    {DASS_21.options.map((option) => (
                      <Dass21TableCellOption
                        key={option.code}
                        optionCode={option.code}
                        invalid={fieldState.invalid}
                      />
                    ))}
                  </TableRow>
                </RadioGroup>
              </FormControl>
            </FormItemWithoutDiv>
          )}
        />
      ))}
    </TableBody>
  );
}
