import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import {
  $assessment,
  resetAssessmentStore,
  // createAssessmentId,
  nextStep,
} from "@/stores/assessment";
import { Checkbox } from "@/components/ui/checkbox";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Card } from "@/components/ui/card";

const tests = [
  {
    id: "ors",
    label: "ORS",
    order: 100,
  },
  {
    id: "dass21",
    label: "DASS 21",
    order: 200,
  },
  {
    id: "srs",
    label: "SRS",
    order: 101,
  },
] as const;

const testOrder: Record<string, number> = {
  ors: 100,
  dass21: 200,
  srs: 101,
};

const FormSchema = z.object({
  code: z
    .string()
    .min(3, "Must have at least 3 letters")
    .max(4, "Must have at most 4 letters"),
  tests: z
    .array(z.string())
    .refine((value) => value.some((item) => item), {
      message: "Select at least one test",
    })
    .refine((value) => value.length < 3, {
      message: "Select a maximum of 2 tests",
    })
    .refine(
      (value) => {
        if (value.length === 2) {
          return value.includes("ors") && value.includes("dass21");
        }
        return true;
      },
      {
        message: "The only valid selection for 2 tests is ORS and DASS 21",
      }
    ),
});

export function Home() {
  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      code: "",
      tests: [],
    },
  });

  function onSubmit(data: z.infer<typeof FormSchema>) {
    resetAssessmentStore();
    // createAssessmentId();
    $assessment.setKey("code", data.code);
    $assessment.setKey(
      "tests",
      data.tests.sort((a, b) => testOrder[a] - testOrder[b])
    );
    nextStep();
  }

  return (
    <>
      <header></header>
      <div className="flex-grow flex flex-col justify-center">
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="space-y-4 flex flex-col justify-center gap-4"
          >
            <Card className="bg-white rounded-2xl px-6 pt-6 pb-4 w-[55%] mx-auto">
              <FormField
                control={form.control}
                name="code"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-xl text-black">Code</FormLabel>
                    <FormDescription>
                      The first 3 or 4 letters of the client's name.
                    </FormDescription>
                    <FormControl>
                      <Input
                        className="border-slate-300 focus-visible:ring-teal-700 aria-[invalid=true]:focus-visible:ring-red-500"
                        autoCapitalize="characters"
                        {...field}
                      />
                    </FormControl>

                    <div className="min-h-5">
                      <FormMessage />
                    </div>
                  </FormItem>
                )}
              />
            </Card>
            <Card className="bg-white rounded-2xl px-6 pt-6 pb-4 w-[55%] mx-auto">
              <FormField
                control={form.control}
                name="tests"
                render={() => (
                  <FormItem className="space-y-4">
                    <div className="mb-4">
                      <FormLabel className="text-xl text-black">
                        Psychometrics
                      </FormLabel>
                    </div>
                    {tests.map((item) => (
                      <FormField
                        key={item.id}
                        control={form.control}
                        name="tests"
                        render={({ field }) => {
                          return (
                            <FormItem
                              key={item.id}
                              className="flex flex-row items-center space-x-3 space-y-0"
                            >
                              <FormControl>
                                <Checkbox
                                  className="h-7 w-7 border-slate-300 bg-white focus-visible:ring-teal-700 data-[state=checked]:border-teal-600 data-[state=checked]:bg-teal-600"
                                  checked={field.value?.includes(item.id)}
                                  onCheckedChange={(checked) => {
                                    return checked
                                      ? field.onChange([
                                          ...field.value,
                                          item.id,
                                        ])
                                      : field.onChange(
                                          field.value?.filter(
                                            (value) => value !== item.id
                                          )
                                        );
                                  }}
                                />
                              </FormControl>
                              <FormLabel className="text-black text-lg">
                                {item.label}
                              </FormLabel>
                            </FormItem>
                          );
                        }}
                      />
                    ))}
                    <div className="min-h-5">
                      <FormMessage />
                    </div>
                  </FormItem>
                )}
              />
            </Card>
            <div className="w-[55%] mx-auto flex justify-end">
              <Button
                type="submit"
                className="bg-teal-600 text-white text-lg focus-visible:ring-teal-700"
              >
                Start
              </Button>
            </div>
          </form>
        </Form>
      </div>
      <footer></footer>
    </>
  );
}
