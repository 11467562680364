import { deepMap, action } from "nanostores";
// import { customAlphabet } from "nanoid";
import { $router } from "./router";
import { openPage } from "@nanostores/router";
import { OrsId } from "@/data/ors";
import { SrsId } from "@/data/srs";
import { DASS_21, Dass21Id } from "@/data/dass-21";

export type Assessment = {
  id: string;
  code: string;
  tests: string[];
  currentStep: string;
  currentStepIndex: number;
  nextStep: string;
  nextStepIndex: number;
  nextButtonText: string;
  ors: Record<OrsId, number>;
  srs: Record<SrsId, number>;
  dass21: Record<Dass21Id, string>;
};

// const nanoid = customAlphabet("abcdefghijklmnopqrstuvwxyz123456789");

function getAssessmentDefaults(): Assessment {
  return {
    id: "",
    code: "",
    tests: [],
    currentStep: "",
    currentStepIndex: -1,
    nextStep: "",
    nextStepIndex: -1,
    nextButtonText: "Next",
    ors: {
      "ors-men": 0,
      "ors-ind": 0,
      "ors-int": 0,
      "ors-soc": 0,
      "ors-ove": 0,
    },
    srs: {
      "srs-rel": 0,
      "srs-goa": 0,
      "srs-app": 0,
      "srs-ove": 0,
    },
    dass21: Object.fromEntries(
      DASS_21.items.map((item) => [item.id, ""])
    ) as Record<Dass21Id, "">,
  };
}

export const $assessment = deepMap<Assessment>(getAssessmentDefaults());

export const resetAssessmentStore = action(
  $assessment,
  "resetAssessmentStore",
  ($store) => {
    $store.set(getAssessmentDefaults());
  }
);

// export const createAssessmentId = action(
//   $assessment,
//   "createAssessmentId",
//   ($store) => {
//     $store.setKey("id", nanoid(6));
//   }
// );

export const nextStep = action($assessment, "nextStep", ($store) => {
  if ($store.get().nextStep == undefined) {
    // TODO: isLastStep
    // we're on the last test, and have clicked submit

    openPage($router, "finish");
  } else {
    // we're either:
    //   - on the home page, and have clicked start
    //   - on a test's page, and have clicked next

    const currentStepIndex = $store.get().currentStepIndex + 1;

    $store.setKey("currentStep", $store.get().tests[currentStepIndex]);
    $store.setKey("currentStepIndex", currentStepIndex);

    const nextStepIndex = currentStepIndex + 1;

    $store.setKey("nextStep", $store.get().tests[nextStepIndex]);
    $store.setKey("nextStepIndex", nextStepIndex);

    if (nextStepIndex >= $store.get().tests.length) {
      $store.setKey("nextButtonText", "Submit");
    }

    openPage($router, "assessment", {
      // id: $store.get().id,
      step: `${currentStepIndex + 1}`, // steps are 1-based
      test: $store.get().tests[currentStepIndex],
    });
  }
});
