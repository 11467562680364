import * as React from "react";
import { useLongPress } from "@uidotdev/usehooks";
import { Button } from "@/components/ui/button";
import { openPage } from "@nanostores/router";
import { $router } from "@/stores/router";

export function Finish() {
  const [showNextButton, setShowNextButton] = React.useState(false);
  const longPressAttrs = useLongPress(
    () => {
      setShowNextButton(true);
    },
    { threshold: 2000 }
  );

  return (
    <>
      <header className="flex flex-col items-center justify-center h-20 py-20">
        <h1 className="max-w-xl text-4xl font-extrabold pb-5">Finished</h1>
      </header>
      <div className="w-2/3 mx-auto flex-grow flex flex-col justify-between items-center">
        <p className="text-center">
          Thank you. Please hand the iPad back to reception.
        </p>
        <div>
          {showNextButton ? (
            <Button
              onClick={() => openPage($router, "results")}
              className="bg-teal-600 text-white text-lg w-fit"
            >
              Go to results
            </Button>
          ) : null}
        </div>
      </div>
      <footer className="h-20 flex justify-center" {...longPressAttrs}></footer>
    </>
  );
}
