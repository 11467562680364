import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { ORS, OrsId } from "@/data/ors";
import { cn } from "@/lib/utils";
import { $assessment } from "@/stores/assessment";
import { useStore } from "@nanostores/react";

export function OrsResults() {
  const assessment = useStore($assessment);
  const sum =
    ORS.items
      .filter((it) => it.id !== OrsId.Men)
      .map((it) => assessment.ors[it.id])
      .reduce((sum, it) => sum + it, 0) / 10;

  return (
    <div>
      <h2 className="text-xl font-bold text-center mb-2">ORS</h2>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead className="p-2 font-bold text-black border-b-2 border-black">
              Scale
            </TableHead>
            <TableHead className="p-2 font-bold text-black border-b-2 border-black">
              Score
            </TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {ORS.items.map(({ id, title }, index) => (
            <TableRow
              key={id}
              className={cn(
                "border-slate-300",
                { "border-b": index !== ORS.items.length - 1 },
                { "bg-slate-200": id !== OrsId.Men }
              )}
            >
              <TableCell className=" p-2">{title}</TableCell>
              <TableCell className=" p-2">{assessment.ors[id] / 10}</TableCell>
            </TableRow>
          ))}
          <TableRow
            className={cn(
              "border-b-2 border-t-2 border-slate-500",
              "bg-slate-300"
            )}
          >
            <TableCell className=" p-2">Sum</TableCell>
            <TableCell className=" p-2">{sum}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
}
