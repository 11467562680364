import { RadioGroupItem } from "@/components/ui/radio-group";
import { TableCell } from "@/components/ui/table";
import { cn } from "@/lib/utils";

type Dass21TableCellOptionProps = {
  optionCode: string;
  invalid: boolean;
};

export function Dass21TableCellOption(props: Dass21TableCellOptionProps) {
  return (
    <TableCell>
      <div className="flex flex-col justify-center items-center">
        <RadioGroupItem
          value={props.optionCode}
          className={cn(
            "data-[state=checked]:border-teal-600",
            "data-[state=checked]:text-teal-600",
            {
              "border-red-600": props.invalid,
              "text-red-600": props.invalid,
              // "border-dashed": props.invalid,x
            }
          )}
        />
      </div>
    </TableCell>
  );
}
