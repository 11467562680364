// type Dass21 = typeof DASS_21;

type Dass21Numbers =
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | 13
  | 14
  | 15
  | 16
  | 17
  | 18
  | 19
  | 20
  | 21;

export type Dass21Id = `dass21-${Dass21Numbers}`;

export type Dass21OptionCode = "N" | "S" | "O" | "AA";

export const Dass21OptionCodeToScoreMapping = {
  N: 0,
  S: 1,
  O: 2,
  AA: 3,
} as const;

type Dass21Config = {
  id: "dass21";
  title: "DASS 21";
  options: Array<{ code: Dass21OptionCode; text: string }>;
  optionCodes: Dass21OptionCode[];
  items: Array<{ id: Dass21Id; number: Dass21Numbers; statement: string }>;
};

export const DASS_21 = {
  id: "dass21",
  title: "DASS 21",
  options: [
    { code: "N", text: "Never" },
    { code: "S", text: "Sometimes" },
    { code: "O", text: "Often" },
    { code: "AA", text: "Almost always" },
  ],
  optionCodes: ["N", "S", "O", "AA"],
  items: [
    {
      id: "dass21-1",
      number: 1,
      statement: "I found it hard to wind down",
    },
    {
      id: "dass21-2",
      number: 2,
      statement: "I was aware of dryness of my mouth",
    },
    {
      id: "dass21-3",
      number: 3,
      statement: "I couldn't seem to experience any positive feeling at all",
    },
    {
      id: "dass21-4",
      number: 4,
      statement:
        "I experienced breathing difficulty (e.g. excessively rapid breathing, breathlessness in the absence of physical exertion)",
    },
    {
      id: "dass21-5",
      number: 5,
      statement: "I found it difficult to work up the initiative to do things",
    },
    {
      id: "dass21-6",
      number: 6,
      statement: "I tended to over-react to situations",
    },
    {
      id: "dass21-7",
      number: 7,
      statement: "I experienced trembling (e.g. in the hands)",
    },
    {
      id: "dass21-8",
      number: 8,
      statement: "I felt that I was using a lot of nervous energy",
    },
    {
      id: "dass21-9",
      number: 9,
      statement:
        "I was worried about situations in which I might panic and make a fool of myself",
    },
    {
      id: "dass21-10",
      number: 10,
      statement: "I felt that I had nothing to look forward to",
    },
    {
      id: "dass21-11",
      number: 11,
      statement: "I found myself getting agitated",
    },
    {
      id: "dass21-12",
      number: 12,
      statement: "I found it difficult to relax",
    },
    {
      id: "dass21-13",
      number: 13,
      statement: "I felt down-hearted and blue",
    },
    {
      id: "dass21-14",
      number: 14,
      statement:
        "I was intolerant of anything that kept me from getting on with what I was doing",
    },
    {
      id: "dass21-15",
      number: 15,
      statement: "I felt I was close to panic",
    },
    {
      id: "dass21-16",
      number: 16,
      statement: "I was unable to become enthusiastic about anything",
    },
    {
      id: "dass21-17",
      number: 17,
      statement: "I felt I wasn't worth much as a person",
    },
    {
      id: "dass21-18",
      number: 18,
      statement: "I felt that I was rather touchy",
    },
    {
      id: "dass21-19",
      number: 19,
      statement:
        "I was aware of the action of my heart in the absence of physical exertion (e.g. sense of heart rate increase, heart missing a beat)",
    },
    {
      id: "dass21-20",
      number: 20,
      statement: "I felt scared without any good reason",
    },
    {
      id: "dass21-21",
      number: 21,
      statement: "I felt that life was meaningless",
    },
  ],
} as const satisfies Dass21Config;
