import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { SRS } from "@/data/srs";
import { cn } from "@/lib/utils";
import { $assessment } from "@/stores/assessment";
import { useStore } from "@nanostores/react";

export function SrsResults() {
  const assessment = useStore($assessment);
  const sum =
    SRS.items
      .map((it) => assessment.srs[it.id])
      .reduce((sum, it) => sum + it, 0) / 10;

  return (
    <div>
      <h2 className="text-xl font-bold text-center mb-2">SRS</h2>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead className="p-2 font-bold text-black border-b-2 border-black">
              Scale
            </TableHead>
            <TableHead className="p-2 font-bold text-black border-b-2 border-black">
              Score
            </TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {SRS.items.map(({ id, title }, index) => (
            <TableRow
              key={id}
              className={cn("border-slate-300", {
                "border-b": index !== SRS.items.length - 1,
              })}
            >
              <TableCell className=" p-2">{title}</TableCell>
              <TableCell className=" p-2">{assessment.srs[id] / 10}</TableCell>
            </TableRow>
          ))}
          <TableRow
            className={cn(
              "border-b-2 border-t-2 border-slate-500",
              "bg-slate-200"
            )}
          >
            <TableCell className=" p-2">Sum</TableCell>
            <TableCell className=" p-2">{sum}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
}
