import { Button } from "@/components/ui/button";
// import { SrsCard } from "./srs-card";
import { SRS, SrsId } from "@/data/srs";
import { Card, CardHeader, CardTitle, CardContent } from "@/components/ui/card";
import { Slider } from "@/components/ui/slider";
import * as z from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Form, FormControl, FormField, FormItem } from "@/components/ui/form";
import { $assessment, nextStep } from "@/stores/assessment";
import { useStore } from "@nanostores/react";

const FormSchema = z.object({
  "srs-rel": z.array(z.number()),
  "srs-goa": z.array(z.number()),
  "srs-app": z.array(z.number()),
  "srs-ove": z.array(z.number()),
} satisfies Record<SrsId, z.ZodArray<z.ZodNumber, "many">>);

export function Srs() {
  const assessment = useStore($assessment);

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      "srs-rel": [0],
      "srs-goa": [0],
      "srs-app": [0],
      "srs-ove": [0],
    },
  });

  function onSubmit(data: z.infer<typeof FormSchema>) {
    $assessment.setKey("srs", {
      "srs-rel": data["srs-rel"][0],
      "srs-goa": data["srs-goa"][0],
      "srs-app": data["srs-app"][0],
      "srs-ove": data["srs-ove"][0],
    });

    nextStep();
  }

  return (
    <>
      <header className="flex flex-col items-center justify-center h-20 pt-20 pb-16">
        <h1 className="max-w-xl text-3xl font-extrabold pb-4">{SRS.title}</h1>
        <p className="max-w-xl text-center">{SRS.introduction}</p>
      </header>
      <div className=" w-5/6 mx-auto flex-grow">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <div className="flex flex-col gap-6">
              {SRS.items.map((srsItem) => (
                <FormField
                  key={srsItem.id}
                  control={form.control}
                  name={srsItem.id}
                  render={({ field }) => (
                    <FormItem>
                      <Card className="bg-white rounded-2xl p-2 min-h-52">
                        <CardHeader className="text-center">
                          <CardTitle>{srsItem.title}</CardTitle>
                        </CardHeader>
                        <FormControl>
                          <CardContent className="flex gap-6 px-4 text-center">
                            <p className="w-80">{srsItem.prefix}</p>
                            <Slider
                              name={field.name}
                              defaultValue={[0]}
                              onValueChange={field.onChange}
                            />
                            <p className="w-80">{srsItem.postfix}</p>
                          </CardContent>
                        </FormControl>
                      </Card>
                    </FormItem>
                  )}
                />
              ))}
              <div className="flex justify-end">
                <Button className="bg-teal-600 text-white text-lg w-fit">
                  {assessment.nextButtonText}
                </Button>
              </div>
            </div>
          </form>
        </Form>
      </div>
      <footer className="h-20"></footer>
    </>
  );
}
