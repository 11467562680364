import { createRouter } from "@nanostores/router";

export const $router = createRouter({
  home: "/",

  ors: "/test/ors",
  srs: "/test/srs",
  dass21: "/test/dass21",

  assessment: "/assessment/:step/:test",
  finish: "/assessment/finish",
  results: "/assessment/results",
});
