import { $assessment } from "@/stores/assessment";
import { useStore } from "@nanostores/react";
import { OrsResults } from "@/components/psych-tests/ors/ors-results";
import { Dass21Results } from "@/components/psych-tests/dass-21/dass-21-results";
import { SrsResults } from "@/components/psych-tests/srs/srs-results";

export function Results() {
  const assessment = useStore($assessment);
  const showOrs = assessment.tests.includes("ors");
  const showSrs = assessment.tests.includes("srs");
  const showDass21 = assessment.tests.includes("dass21");

  return (
    <>
      <header className="flex flex-col items-center justify-center h-20 py-20">
        <h1 className="max-w-xl text-4xl font-extrabold pb-5">Results</h1>
      </header>
      <div className="flex-grow px-20 flex flex-col gap-16">
        {showOrs ? (
          <div className="max-w-80">
            <OrsResults />
          </div>
        ) : null}
        {showDass21 ? (
          <div className="max-w-md">
            <Dass21Results />
          </div>
        ) : null}
        {showSrs ? (
          <div className="max-w-80">
            <SrsResults />
          </div>
        ) : null}
      </div>
      <footer className="h-20"></footer>
    </>
  );
}
