import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  Dass21OptionCode,
  Dass21OptionCodeToScoreMapping,
} from "@/data/dass-21";
import { $assessment, Assessment } from "@/stores/assessment";
import { useStore } from "@nanostores/react";

type ScaleResult = {
  totalScore: number;
  label: "Normal" | "Mild" | "Moderate" | "Severe" | "Extremeley Severe";
};

const ScaleResultLabel = {
  Normal: "Normal",
  Mild: "Mild",
  Moderate: "Moderate",
  Severe: "Severe",
  ExtremelySevere: "Extremeley Severe",
} as const satisfies Record<string, ScaleResult["label"]>;

function calculateDepressionResult(data: Assessment["dass21"]): ScaleResult {
  const optionCodeAnswers = [
    data["dass21-3"],
    data["dass21-5"],
    data["dass21-10"],
    data["dass21-13"],
    data["dass21-16"],
    data["dass21-17"],
    data["dass21-21"],
  ] as Dass21OptionCode[];

  const scoreAnswers = optionCodeAnswers.map(
    (it) => Dass21OptionCodeToScoreMapping[it]
  );

  const totalScore = scoreAnswers.reduce((sum: number, it) => sum + it, 0);

  let depressionLabel: ScaleResult["label"] = ScaleResultLabel.ExtremelySevere;
  if (totalScore >= 0 && totalScore <= 4) {
    depressionLabel = ScaleResultLabel.Normal;
  } else if (totalScore >= 5 && totalScore <= 6) {
    depressionLabel = ScaleResultLabel.Mild;
  } else if (totalScore >= 7 && totalScore <= 10) {
    depressionLabel = ScaleResultLabel.Moderate;
  } else if (totalScore >= 11 && totalScore <= 13) {
    depressionLabel = ScaleResultLabel.Severe;
  }

  const scaleResult = { totalScore, label: depressionLabel };

  return scaleResult;
}

function calculateAnxietyResult(data: Assessment["dass21"]): ScaleResult {
  const optionCodeAnswers = [
    data["dass21-2"],
    data["dass21-4"],
    data["dass21-7"],
    data["dass21-9"],
    data["dass21-15"],
    data["dass21-19"],
    data["dass21-20"],
  ] as Dass21OptionCode[];

  const scoreAnswers = optionCodeAnswers.map(
    (it) => Dass21OptionCodeToScoreMapping[it]
  );

  const totalScore = scoreAnswers.reduce((sum: number, it) => sum + it, 0);

  let anxietyLabel: ScaleResult["label"] = ScaleResultLabel.ExtremelySevere;
  if (totalScore >= 0 && totalScore <= 3) {
    anxietyLabel = ScaleResultLabel.Normal;
  } else if (totalScore >= 4 && totalScore <= 5) {
    anxietyLabel = ScaleResultLabel.Mild;
  } else if (totalScore >= 6 && totalScore <= 7) {
    anxietyLabel = ScaleResultLabel.Moderate;
  } else if (totalScore >= 8 && totalScore <= 9) {
    anxietyLabel = ScaleResultLabel.Severe;
  }

  const scaleResult = { totalScore, label: anxietyLabel };

  return scaleResult;
}

function calculateStressResult(data: Assessment["dass21"]): ScaleResult {
  const optionCodeAnswers = [
    data["dass21-1"],
    data["dass21-6"],
    data["dass21-8"],
    data["dass21-11"],
    data["dass21-12"],
    data["dass21-14"],
    data["dass21-18"],
  ] as Dass21OptionCode[];

  const scoreAnswers = optionCodeAnswers.map(
    (it) => Dass21OptionCodeToScoreMapping[it]
  );

  const totalScore = scoreAnswers.reduce((sum: number, it) => sum + it, 0);

  let stressLabel: ScaleResult["label"] = ScaleResultLabel.ExtremelySevere;
  if (totalScore >= 0 && totalScore <= 7) {
    stressLabel = ScaleResultLabel.Normal;
  } else if (totalScore >= 8 && totalScore <= 9) {
    stressLabel = ScaleResultLabel.Mild;
  } else if (totalScore >= 10 && totalScore <= 12) {
    stressLabel = ScaleResultLabel.Moderate;
  } else if (totalScore >= 13 && totalScore <= 16) {
    stressLabel = ScaleResultLabel.Severe;
  }

  const scaleResult = { totalScore, label: stressLabel };

  return scaleResult;
}

export function Dass21Results() {
  const assessment = useStore($assessment);
  const depressionResult = calculateDepressionResult(assessment.dass21);
  const anxietyResult = calculateAnxietyResult(assessment.dass21);
  const stressResult = calculateStressResult(assessment.dass21);

  return (
    <div>
      <h2 className="text-xl font-bold text-center mb-2">DASS 21</h2>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead className="p-2 font-bold text-black border-b-2 border-black">
              Scale
            </TableHead>
            <TableHead className="p-2 font-bold text-black border-b-2 border-black">
              Score
            </TableHead>
            <TableHead className="p-2 font-bold text-black border-b-2 border-black">
              Label
            </TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          <TableRow className="border-b-2">
            <TableCell className="p-2">Depression</TableCell>
            <TableCell className="p-2">{depressionResult.totalScore}</TableCell>
            <TableCell className="p-2">{depressionResult.label}</TableCell>
          </TableRow>
          <TableRow className="border-b-2">
            <TableCell className="p-2">Anxiety</TableCell>
            <TableCell className="p-2">{anxietyResult.totalScore}</TableCell>
            <TableCell className="p-2">{anxietyResult.label}</TableCell>
          </TableRow>
          <TableRow className="border-b-2 border-black">
            <TableCell className="p-2">Stress</TableCell>
            <TableCell className="p-2">{stressResult.totalScore}</TableCell>
            <TableCell className="p-2">{stressResult.label}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
}
