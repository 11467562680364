import { useStore } from "@nanostores/react";
import { $router } from "@/stores/router.js";
import { Home } from "@/components/pages/home";
import { Dass21 } from "@/components/psych-tests/dass-21/dass-21";
import { Ors } from "@/components/psych-tests/ors/ors";
import { Srs } from "@/components/psych-tests/srs/srs";
import { Finish } from "@/components/pages/finish";
import { Results } from "@/components/pages/results";
import "./App.css";

function App() {
  const page = useStore($router);

  let PsychTest = () => (
    <p>Blank page - if you're seeing this, something has gone wrong.</p>
  );
  if (!page) {
    PsychTest = () => <p>Error 404</p>;
  } else if (page.route === "home") {
    PsychTest = Home;
  } else if (page.route === "ors") {
    PsychTest = Ors;
  } else if (page.route === "srs") {
    PsychTest = Srs;
  } else if (page.route === "dass21") {
    PsychTest = Dass21;
  } else if (page.route === "assessment") {
    if (page.params.test === "ors") {
      PsychTest = Ors;
    } else if (page.params.test === "srs") {
      PsychTest = Srs;
    } else if (page.params.test === "dass21") {
      PsychTest = Dass21;
    } else {
      PsychTest = () => <p>Invalid test id.</p>;
    }
  } else if (page.route === "finish") {
    PsychTest = Finish;
  } else if (page.route === "results") {
    PsychTest = Results;
  }

  return (
    <div className="bg-gray-50 min-h-screen flex flex-col">
      <PsychTest />
    </div>
  );
}

export default App;
