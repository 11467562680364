export type OrsId = (typeof ORS)["items"][number]["id"];

export const OrsId = {
  Men: "ors-men",
  Ind: "ors-ind",
  Int: "ors-int",
  Soc: "ors-soc",
  Ove: "ors-ove",
} as const;

export const ORS = {
  id: "ors",
  title: "Outcome Rating Scale",
  introduction:
    "Looking back over the last week, including today, how have you been doing in the following areas of your life?",
  items: [
    {
      id: "ors-men",
      title: "Mental Health",
      subtitle: "Psychological well-being",
      prefix: "Low",
      postfix: "High",
    },
    {
      id: "ors-ind",
      title: "Individually",
      subtitle: "Personal well-being",
      prefix: "Low",
      postfix: "High",
    },
    {
      id: "ors-int",
      title: "Interpersonally",
      subtitle: "Family, close relationships",
      prefix: "Low",
      postfix: "High",
    },
    {
      id: "ors-soc",
      title: "Socially",
      subtitle: "Work, school, friendships",
      prefix: "Low",
      postfix: "High",
    },
    {
      id: "ors-ove",
      title: "Overall",
      subtitle: "General sense of well-being",
      prefix: "Low",
      postfix: "High",
    },
  ] as const,
};
