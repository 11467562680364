export type SrsId = (typeof SRS)["items"][number]["id"];

export const SRS = {
  id: "srs",
  title: "Session Rating Scale",
  introduction: "How did you find today's session in the following areas?",
  items: [
    {
      id: "srs-rel",
      title: "Relationship",
      prefix: "I did not feel heard, understood, and respected.",
      postfix: "I felt heard, understood, and respected.",
    },
    {
      id: "srs-goa",
      title: "Goals and Topics",
      prefix:
        "We did not work on or talk about what I wanted to work on and talk about.",
      postfix:
        "We worked on and talked about what I wanted to work on and talk about.",
    },
    {
      id: "srs-app",
      title: "Approach or Method",
      prefix: "The therapist's approach is not a good fit for me.",
      postfix: "The therapist's approach is a good fit for me.",
    },
    {
      id: "srs-ove",
      title: "Overall",
      prefix: "There was something missing in the session today.",
      postfix: "Overall, today's session was right for me.",
    },
  ] as const,
};
