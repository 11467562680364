import { TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { DASS_21 } from "@/data/dass-21";

export function Dass21TableHeader() {
  return (
    <TableHeader className="bg-gray-50 sticky top-0">
      <TableRow>
        <TableHead className="font-bold text-black" colSpan={2}>
          Over the past week...
        </TableHead>
        {DASS_21.options.map((option) => (
          <TableHead
            className="font-bold text-black text-center"
            key={option.code}
          >
            {option.code}
          </TableHead>
        ))}
      </TableRow>
    </TableHeader>
  );
}
