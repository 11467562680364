import { Table } from "@/components/ui/table";
import { DASS_21, Dass21Id } from "@/data/dass-21";
import { Dass21TableHeader } from "./dass-21-table-header";
import { Dass21TableBody } from "./dass-21-table-body";
import { $assessment, nextStep } from "@/stores/assessment";
import { useStore } from "@nanostores/react";
import { Button } from "@/components/ui/button";
import * as z from "zod";
import { FieldErrors, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Form } from "@/components/ui/form";

// const OptionCodeSchema = z.union([
//   z.literal("N"),
//   z.literal("S"),
//   z.literal("O"),
//   z.literal("A"),
// ]);

// const FormSchemaObject = Object.fromEntries(
//   DASS_21.items.map((item) => [item.id, OptionCodeSchema])
// ) as Record<Dass21Id, typeof OptionCodeSchema>;

const FormSchemaObject = Object.fromEntries(
  DASS_21.items.map((item) => [item.id, z.string().min(1).max(2)])
) as Record<Dass21Id, z.ZodString>;

const FormSchemaObjectDefaults = Object.fromEntries(
  DASS_21.items.map((item) => [item.id, ""])
) as Record<Dass21Id, "">;

const FormSchema = z.object(FormSchemaObject);

export function Dass21() {
  const assessment = useStore($assessment);

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: FormSchemaObjectDefaults,
  });

  function onSubmit(data: z.infer<typeof FormSchema>) {
    $assessment.setKey("dass21", { ...data });

    nextStep();
  }

  return (
    <>
      <header className="bg-red-00 flex flex-col items-center justify-center min-h-20 pt-20 pb-14 max-w-2xl mx-auto">
        <h1 className="text-4xl font-extrabold pb-5">{DASS_21.title}</h1>
        <div>
          <p className="mb-2">
            Please read each statement below and select <strong>N</strong>,{" "}
            <strong>S</strong>, <strong>O</strong>, or <strong>AA</strong>,
            which indicate how much the statement applied to you{" "}
            <span className=" underline decoration-solid decoration-black decoration-2">
              over the past week
            </span>
            . There are no right or wrong answers. Do not spend too much time on
            any statement. The rating scale is as follows:
          </p>
          <ul>
            <li>
              <strong>N</strong> &#8212; <strong>NEVER</strong> &#8212; Did not
              apply to me at all
            </li>
            <li>
              <strong>S</strong> &#8212; <strong>SOMETIMES</strong> &#8212;
              Applied to me to some degree, or some of the time
            </li>
            <li>
              <strong>O</strong> &#8212; <strong>OFTEN</strong> &#8212; Applied
              to me to a considerable degree, or a good part of the time
            </li>
            <li>
              <strong>AA</strong> &#8212; <strong>ALMOST ALWAYS</strong> &#8212;
              Applied to me very much, or most of the time
            </li>
          </ul>
        </div>
      </header>
      <div className="w-full max-w-2xl mx-auto flex-grow flex flex-col gap-8">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <Table>
              <Dass21TableHeader />
              <Dass21TableBody />
            </Table>
            <ErrorMessage formErrors={form.formState.errors} />
            <div className="flex justify-end">
              <Button className="bg-teal-600 text-white text-lg w-fit">
                {assessment.nextButtonText}
              </Button>
            </div>
          </form>
        </Form>
      </div>
      <footer className="bg-blue-00 h-20"></footer>
    </>
  );
}

type ErrorMessageProps = React.PropsWithoutRef<{
  formErrors: FieldErrors<Record<Dass21Id, string>>;
}>;

function ErrorMessage(props: ErrorMessageProps) {
  const unansweredNumbers = Object.entries(props.formErrors).map(([key]) =>
    key.slice(key.indexOf("-") + 1)
  );

  let errorMessageText = "";

  if (unansweredNumbers.length === 1) {
    errorMessageText = `Statement ${unansweredNumbers[0]} must still be answered.`;
  } else if (unansweredNumbers.length === 2) {
    errorMessageText = `Statements ${unansweredNumbers[0]} and ${unansweredNumbers[1]} must still be answered.`;
  } else if (unansweredNumbers.length > 2) {
    errorMessageText = `Statements ${unansweredNumbers
      .slice(0, unansweredNumbers.length - 1)
      .join(", ")}, and ${
      unansweredNumbers[unansweredNumbers.length - 1]
    } must still be answered.`;
  }

  return (
    <p className="mt-2 min-h-6 text-red-600 font-bold">{errorMessageText}</p>
  );
}
