import { Button } from "@/components/ui/button";
// import { OrsCard } from "./ors-card";
import { ORS, type OrsId } from "@/data/ors";
import { useStore } from "@nanostores/react";
import { $assessment, nextStep } from "@/stores/assessment";
import * as z from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Form, FormControl, FormField, FormItem } from "@/components/ui/form";
import {
  Card,
  CardHeader,
  CardTitle,
  CardDescription,
  CardContent,
} from "@/components/ui/card";
import { Slider } from "@/components/ui/slider";

const FormSchema = z.object({
  "ors-men": z.array(z.number()),
  "ors-ind": z.array(z.number()),
  "ors-int": z.array(z.number()),
  "ors-soc": z.array(z.number()),
  "ors-ove": z.array(z.number()),
} satisfies Record<OrsId, z.ZodArray<z.ZodNumber, "many">>);

export function Ors() {
  const assessment = useStore($assessment);

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      "ors-men": [0],
      "ors-ind": [0],
      "ors-int": [0],
      "ors-soc": [0],
      "ors-ove": [0],
    },
  });

  function onSubmit(data: z.infer<typeof FormSchema>) {
    $assessment.setKey("ors", {
      "ors-men": data["ors-men"][0],
      "ors-ind": data["ors-ind"][0],
      "ors-int": data["ors-int"][0],
      "ors-soc": data["ors-soc"][0],
      "ors-ove": data["ors-ove"][0],
    });

    nextStep();
  }

  return (
    <>
      <header className="flex flex-col items-center justify-center h-20 py-20">
        <h1 className="max-w-xl text-4xl font-extrabold pb-5">{ORS.title}</h1>
        <p className="max-w-xl text-center">{ORS.introduction}</p>
      </header>
      <div className="w-2/3 mx-auto flex-grow">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <div className="flex flex-col gap-8">
              {ORS.items.map((orsItem) => (
                <FormField
                  key={orsItem.id}
                  control={form.control}
                  name={orsItem.id}
                  render={({ field }) => (
                    <FormItem>
                      <Card className="bg-white rounded-2xl">
                        <CardHeader className="text-center">
                          <CardTitle>{orsItem.title}</CardTitle>
                          <CardDescription className="font-semibold">
                            {orsItem.subtitle}
                          </CardDescription>
                        </CardHeader>
                        <FormControl>
                          <CardContent className="flex gap-5 px-16">
                            <span>{orsItem.prefix ?? "Low"}</span>
                            <Slider
                              name={field.name}
                              defaultValue={[0]}
                              onValueChange={field.onChange}
                            />
                            <span>{orsItem.postfix ?? "High"}</span>
                          </CardContent>
                        </FormControl>
                      </Card>
                    </FormItem>
                  )}
                />
              ))}
              <div className="flex justify-end">
                <Button className="bg-teal-600 text-white text-lg w-fit">
                  {assessment.nextButtonText}
                </Button>
              </div>
            </div>
          </form>
        </Form>
      </div>
      <footer className="h-20"></footer>
    </>
  );
}
